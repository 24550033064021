if (typeof bgID !== 'undefined' ) {
	// YouTube IFrame API ////////////////////////////////////////////////////////////////
	// https://developers.google.com/youtube/iframe_api_reference ////////////////////////
	var player;
	var _curClick = 0; // click array
	function onYouTubeIframeAPIReady() {
		player = new YT.Player('player', {
	//		height: '390',
	//		width: '640',
			videoId: bgID[_curClick],
			playerVars: {
				'controls': 0,
				'rel': 0,
				'wmode': "opaque",
				'mute': 1
			},
			events: {
				'onReady': onPlayerReady,
				'onStateChange': onPlayerStateChange
			}
		});
	}
	var done = false;
	function onPlayerReady(event) {
		event.target.setVolume(80);
		event.target.playVideo();
	}
	function onPlayerStateChange(event) {
		if (event.data === 0) {
			_curClick++;
			if(parseInt(_curClick) > ($(".control li").size() - 1)) {
				_curClick = 0;
				$(".control li.on").removeClass("on");
				$(".control li:eq(0)").addClass("on");
				event.target.loadVideoById(bgID[parseInt(_curClick)]);
			} else {
				$(".control li.on").removeClass("on").next("li").addClass("on");
				event.target.loadVideoById(bgID[parseInt(_curClick)]);
			}
		}
	}
	//////////////////////////////////////////////////////////////////////////////////////

	$(function(){
		// click event
		$(".control li").each(function(){
			$(this).on("click",function() {
				_curClick = $(this).index();
				$(".control li").removeClass("on");
				$(this).addClass("on");
				player.loadVideoById(bgID[parseInt(_curClick)]);
			});
		});

		$(".btn_pop").on("click",function() {
			player.pauseVideo();
			$(".pop_yt iframe").attr("src", "https://www.youtube.com/embed/"+ popID[_curClick] +"?rel=0&amp;autoplay=1;&amp;wmode=opaque");
			$(".pop_yt").show();
			$(".pop_overlay").show();
		});

		$(".pop_yt .close").on("click",function() {
			$(".pop_yt").hide();
			$(".pop_overlay").hide();
			$(".pop_yt iframe").attr("src", "");
			player.playVideo();
		});

		$(".btn_vol").on("click",function() {
			if($(this).hasClass("off")) {
				player.unMute();
				$(this).removeClass("off").addClass("on");
			} else {
				player.mute();
				$(this).removeClass("on").addClass("off");
			}
		});

		$('.btn_play').on("click", function(){
			$(this).removeClass('active');
			$('.btn_pause').addClass('active');
			player.playVideo();
		});

		$('.btn_pause').on("click", function(){
			$(this).removeClass('active');
			$('.btn_play').addClass('active');
			player.pauseVideo();
		});


		// check height
		if(location.href.indexOf("?h=600") > -1) {
			$(".top_box").css("height","600");
		}
	});
}